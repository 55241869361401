<template>
  <v-container
    id="sensing-data"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          color="orange"
          full-header
          class="card-group"
        >
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon
                  large
                  class="mr-3"
                >
                  mdi-home-variant-outline
                </v-icon>
                室內 <span class="room-number">1</span>
              </div>
            </div>
          </template>
          <div class="ma-5">
            <v-card
              elevation="3"
              class="pa-5"
            >
              <v-row class="align-center">
                <div class="d-flex justify-space-between w-100">
                  <v-icon
                    color="red"
                    x-large
                    class="sensing-icon"
                  >
                    mdi-thermometer
                  </v-icon>
                  <div class="ms-2 mr-auto align-self-center temperature-title">
                    溫度
                    <div class="temperature-hint">
                      暫存器：1 紀錄中
                    </div>
                  </div>
                  <div class="mx-2 temperature-number">
                    {{ tweenedTemperature.toFixed(1) }}℃
                  </div>
                </div>
                <div class="d-flex w-100 justify-end">
                  <v-dialog
                    v-model="dialog"
                    max-width="600px"
                    persistent
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="btn-right mr-2 justify-end seven-days-h"
                        v-bind="attrs"
                        v-on="on"
                      >
                        七天歷史資料
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        七天歷史資料
                      </v-card-title>

                      <!-- 圖表 -->
                      <v-card-text class="pt-5">
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col
                                v-for="(chart, i) in charts"
                                :key="`chart-${i}`"
                                cols="12"
                              >
                                <material-chart-card
                                  :color="chart.color"
                                  :data="chart.data"
                                  :options="chart.options"
                                  :responsive-options="chart.responsiveOptions"
                                  :title="chart.title"
                                  :type="chart.type"
                                >
                                  <template #subtitle>
                                    <div class="font-weight-light text--secondary">
                                      <div v-html="chart.subtitle" />
                                    </div>
                                  </template>

                                  <template #actions>
                                    <v-icon
                                      class="mr-1"
                                      small
                                    >
                                      mdi-clock-outline
                                    </v-icon>

                                    <span
                                      class="text-caption grey--text font-weight-light"
                                      v-text="chart.time"
                                    />
                                  </template>
                                </material-chart-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                        >
                          關閉
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-row>
            </v-card>
          </div>
          <div class="ma-5 pb-5">
            <v-card
              elevation="3"
              class="pa-5"
            >
              <v-row class="align-center">
                <div class="d-flex justify-space-between w-100">
                  <v-icon
                    color="blue"
                    x-large
                    class="sensing-icon"
                  >
                    mdi-water-percent
                  </v-icon>
                  <div class="ms-2 mr-auto align-self-center temperature-title">
                    濕度
                    <div class="temperature-hint">
                      暫存器：1 紀錄中
                    </div>
                  </div>
                  <div class="mx-2 temperature-number">
                    {{ tweenedHumidity.toFixed(1) }}RH
                  </div>
                </div>
                <div class="d-flex w-100 justify-end">
                  <v-dialog
                    v-model="dialog"
                    max-width="600px"
                    persistent
                    :retain-focus="false"
                    class="mr-4"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="btn-right mr-2 justify-end seven-days-h"
                        v-bind="attrs"
                        v-on="on"
                      >
                        七天歷史資料
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        七天歷史資料
                      </v-card-title>

                      <!-- 圖表 -->
                      <v-card-text class="pt-5">
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col
                                v-for="(chart, i) in charts"
                                :key="`chart-${i}`"
                                cols="12"
                              >
                                <material-chart-card
                                  :color="chart.color"
                                  :data="chart.data"
                                  :options="chart.options"
                                  :responsive-options="chart.responsiveOptions"
                                  :title="chart.title"
                                  :type="chart.type"
                                >
                                  <template #subtitle>
                                    <div class="font-weight-light text--secondary">
                                      <div v-html="chart.subtitle" />
                                    </div>
                                  </template>

                                  <template #actions>
                                    <v-icon
                                      class="mr-1"
                                      small
                                    >
                                      mdi-clock-outline
                                    </v-icon>

                                    <span
                                      class="text-caption grey--text font-weight-light"
                                      v-text="chart.time"
                                    />
                                  </template>
                                </material-chart-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                        >
                          關閉
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-row>
            </v-card>
          </div>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          color="orange"
          full-header
          class="card-group"
        >
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon
                  large
                  class="mr-3"
                >
                  mdi-home-variant-outline
                </v-icon>
                室內 <span class="room-number">2</span>
              </div>
            </div>
          </template>
          <div class="ma-5">
            <v-card
              elevation="3"
              class="pa-5"
            >
              <v-row class="align-center">
                <div class="d-flex justify-space-between w-100">
                  <v-icon
                    color="red"
                    x-large
                    class="sensing-icon"
                  >
                    mdi-thermometer
                  </v-icon>
                  <div class="ms-2 mr-auto align-self-center temperature-title">
                    溫度
                    <div class="temperature-hint">
                      暫存器：1 紀錄中
                    </div>
                  </div>
                  <div class="mx-2 temperature-number">
                    {{ tweenedTemperature.toFixed(1) }}℃
                  </div>
                </div>
                <div class="d-flex w-100 justify-end">
                  <v-dialog
                    width="100%"
                    class="mr-4"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="btn-right mr-2 justify-end seven-days-h"
                        v-bind="attrs"
                        v-on="on"
                      >
                        七天歷史資料
                      </v-btn>
                    </template>
                  </v-dialog>
                </div>
              </v-row>
            </v-card>
          </div>
          <div class="ma-5 pb-5">
            <v-card
              elevation="3"
              class="pa-5"
            >
              <v-row class="align-center">
                <div class="d-flex justify-space-between w-100">
                  <v-icon
                    color="blue"
                    x-large
                    class="sensing-icon"
                  >
                    mdi-water-percent
                  </v-icon>
                  <div class="ms-2 mr-auto align-self-center temperature-title">
                    濕度
                    <div class="temperature-hint">
                      暫存器：1 紀錄中
                    </div>
                  </div>
                  <div class="mx-2 temperature-number">
                    {{ tweenedHumidity.toFixed(1) }}RH
                  </div>
                </div>
                <div class="d-flex w-100 justify-end">
                  <v-dialog
                    width="100%"
                    class="mr-4"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="btn-right mr-2 justify-end seven-days-h"
                        v-bind="attrs"
                        v-on="on"
                      >
                        七天歷史資料
                      </v-btn>
                    </template>
                  </v-dialog>
                </div>
              </v-row>
            </v-card>
          </div>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          color="orange"
          full-header
          class="card-group"
        >
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon
                  large
                  class="mr-3"
                >
                  mdi-home-variant-outline
                </v-icon>
                室內 <span class="room-number">3</span>
              </div>
            </div>
          </template>
          <div class="ma-5">
            <v-card
              elevation="3"
              class="pa-5"
            >
              <v-row class="align-center">
                <div class="d-flex justify-space-between w-100">
                  <v-icon
                    color="red"
                    x-large
                    class="sensing-icon"
                  >
                    mdi-thermometer
                  </v-icon>
                  <div class="ms-2 mr-auto align-self-center temperature-title">
                    溫度
                    <div class="temperature-hint">
                      暫存器：1 紀錄中
                    </div>
                  </div>
                  <div class="mx-2 temperature-number">
                    {{ tweenedTemperature.toFixed(1) }}℃
                  </div>
                </div>
                <div class="d-flex w-100 justify-end">
                  <v-dialog
                    v-model="dialog"
                    max-width="600px"
                    persistent
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="btn-right mr-2 justify-end seven-days-h"
                        v-bind="attrs"
                        v-on="on"
                      >
                        七天歷史資料
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        七天歷史資料
                      </v-card-title>

                      <!-- 圖表 -->
                      <v-card-text class="pt-5">
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col
                                v-for="(chart, i) in charts"
                                :key="`chart-${i}`"
                                cols="12"
                              >
                                <material-chart-card
                                  :color="chart.color"
                                  :data="chart.data"
                                  :options="chart.options"
                                  :responsive-options="chart.responsiveOptions"
                                  :title="chart.title"
                                  :type="chart.type"
                                >
                                  <template #subtitle>
                                    <div class="font-weight-light text--secondary">
                                      <div v-html="chart.subtitle" />
                                    </div>
                                  </template>

                                  <template #actions>
                                    <v-icon
                                      class="mr-1"
                                      small
                                    >
                                      mdi-clock-outline
                                    </v-icon>

                                    <span
                                      class="text-caption grey--text font-weight-light"
                                      v-text="chart.time"
                                    />
                                  </template>
                                </material-chart-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                        >
                          關閉
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-row>
            </v-card>
          </div>
          <div class="ma-5 pb-5">
            <v-card
              elevation="3"
              class="pa-5"
            >
              <v-row class="align-center">
                <div class="d-flex justify-space-between w-100">
                  <v-icon
                    color="blue"
                    x-large
                    class="sensing-icon"
                  >
                    mdi-water-percent
                  </v-icon>
                  <div class="ms-2 mr-auto align-self-center temperature-title">
                    濕度
                    <div class="temperature-hint">
                      暫存器：1 紀錄中
                    </div>
                  </div>
                  <div class="mx-2 temperature-number">
                    {{ tweenedHumidity.toFixed(1) }}RH
                  </div>
                </div>
                <div class="d-flex w-100 justify-end">
                  <v-dialog
                    v-model="dialog"
                    max-width="600px"
                    persistent
                    :retain-focus="false"
                    class="mr-4"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="btn-right mr-2 justify-end seven-days-h"
                        v-bind="attrs"
                        v-on="on"
                      >
                        七天歷史資料
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        七天歷史資料
                      </v-card-title>

                      <!-- 圖表 -->
                      <v-card-text class="pt-5">
                        <v-row>
                          <v-col cols="12">
                            <v-row>
                              <v-col
                                v-for="(chart, i) in charts"
                                :key="`chart-${i}`"
                                cols="12"
                              >
                                <material-chart-card
                                  :color="chart.color"
                                  :data="chart.data"
                                  :options="chart.options"
                                  :responsive-options="chart.responsiveOptions"
                                  :title="chart.title"
                                  :type="chart.type"
                                >
                                  <template #subtitle>
                                    <div class="font-weight-light text--secondary">
                                      <div v-html="chart.subtitle" />
                                    </div>
                                  </template>

                                  <template #actions>
                                    <v-icon
                                      class="mr-1"
                                      small
                                    >
                                      mdi-clock-outline
                                    </v-icon>

                                    <span
                                      class="text-caption grey--text font-weight-light"
                                      v-text="chart.time"
                                    />
                                  </template>
                                </material-chart-card>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-divider />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                        >
                          關閉
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-row>
            </v-card>
          </div>
        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          color="orange"
          full-header
          class="card-group"
        >
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon
                  large
                  class="mr-3"
                >
                  mdi-home-variant-outline
                </v-icon>
                室內 <span class="room-number">4</span>
              </div>
            </div>
          </template>
          <div class="ma-5">
            <v-card
              elevation="3"
              class="pa-5"
            >
              <v-row class="align-center">
                <div class="d-flex justify-space-between w-100">
                  <v-icon
                    color="red"
                    x-large
                    class="sensing-icon"
                  >
                    mdi-thermometer
                  </v-icon>
                  <div class="ms-2 mr-auto align-self-center temperature-title">
                    溫度
                    <div class="temperature-hint">
                      暫存器：1 紀錄中
                    </div>
                  </div>
                  <div class="mx-2 temperature-number">
                    {{ tweenedTemperature.toFixed(1) }}℃
                  </div>
                </div>
                <div class="d-flex w-100 justify-end">
                  <v-dialog
                    width="100%"
                    class="mr-4"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="btn-right mr-2 justify-end seven-days-h"
                        v-bind="attrs"
                        v-on="on"
                      >
                        七天歷史資料
                      </v-btn>
                    </template>
                  </v-dialog>
                </div>
              </v-row>
            </v-card>
          </div>
          <div class="ma-5 pb-5">
            <v-card
              elevation="3"
              class="pa-5"
            >
              <v-row class="align-center">
                <div class="d-flex justify-space-between w-100">
                  <v-icon
                    color="blue"
                    x-large
                    class="sensing-icon"
                  >
                    mdi-water-percent
                  </v-icon>
                  <div class="ms-2 mr-auto align-self-center temperature-title">
                    濕度
                    <div class="temperature-hint">
                      暫存器：1 紀錄中
                    </div>
                  </div>
                  <div class="mx-2 temperature-number">
                    {{ tweenedHumidity.toFixed(1) }}RH
                  </div>
                </div>
                <div class="d-flex w-100 justify-end">
                  <v-dialog
                    width="100%"
                    class="mr-4"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        class="btn-right mr-2 justify-end seven-days-h"
                        v-bind="attrs"
                        v-on="on"
                      >
                        七天歷史資料
                      </v-btn>
                    </template>
                  </v-dialog>
                </div>
              </v-row>
            </v-card>
          </div>
        </material-card>
      </v-col>
    </v-row>

    <!-- 由於 Indoor Map 寫在 index.html，每個 Component 都要建一個 id="myMap" 的元素，否則 console 會有錯誤訊息，更嚴謹的處理方案待研究。 -->
    <div id="myMap" />
  </v-container>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'SensingData',
  data: () => ({
    charts: [{
      type: 'Line',
      color: 'success',
      title: 'Daily Sales',
      subtitle: '<i class="mdi mdi-arrow-up green--text"></i><span class="green--text">55%</span>&nbsp;increase in today\'s sales',
      time: 'updated 4 minutes ago',
      data: {
        labels: ['Sun', 'Mon', 'Tue', 'Wen', 'Thr', 'Fri', 'Sat'],
        series: [
          [-10, 20, -10, 5, 30, 10, 5]
        ]
      },
      options: {
        low: -15,
        high: 30,
        fullWidth: true,
        width: '200%',
        height: 300,
        lineSmooth: false,
        chartPadding: {
          top: 15,
          right: 15,
          bottom: -15,
          left: -10
        }
      }
    }],
    dialog: false,
    temperature: 0,
    tweenedTemperature: 0,
    humidity: 0,
    tweenedHumidity: 0
  }),

  computed: {},

  watch: {
    temperature (newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: 'circ.out',
        tweenedTemperature: newValue
      })
    },
    humidity (newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: 'circ.out',
        tweenedHumidity: newValue
      })
    }
  },

  created () {
    setInterval(this.randomTemperature, 3000)
    setInterval(this.randomHumidity, 3000)
  },

  mounted () {
    this.randomTemperature()
    this.randomHumidity()
  },

  methods: {
    randomTemperature: function () {
      // 取整數寫法
      this.temperature = Math.floor(Math.random() * (10 - 1 + 1)) + 20
    },
    randomHumidity: function () {
      // 完整小數點位寫法
      this.humidity = Math.random() * (10 - 1 + 1) + 45
    }
  }
}
</script>

<style scoped lang="scss">
  #myMap {
    display: none;
  }
  .v-input__slot > .v-label {
    -webkit-order: 1;
    order: 1;
    font-weight: 800;
  }
  .v-input__slot > .v-input--selection-controls__input {
    -webkit-order: 2;
    order: 2;
  }
  .w-100 {
    width: 100% !important;
  }
  .v-messages {
    margin-bottom: 1em;
  }
  .temperature-title {
    font-size: 18px;
    font-weight: 800;
  }
  .temperature-hint {
    font-size: 14px;
    font-weight: 500;
  }
  .temperature-number {
    font-size: 3.3em;
    color: #999;
  }
  @media (max-width: 414px) {
    .temperature-number {
      font-size: 2.5em;
    }
  }
  .seven-days-h {
    color: #979595;
  }
  .sensing-icon:before {
    font-size: 50px;
  }
  .room-number {
    padding-left: 4px;
    position: relative;
    top: 6px;
    font-size: 2em;
  }
  /* 圖表 */
  .v-dialog {
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 999 !important;
  }
  /* @media (max-width: 414px) {
    .v-dialog {
      max-width: 400px;
    }
  } */
  .ct-series-a {
    stroke-dasharray: 1093;
    stroke-dashoffset: 1093;
    animation: draw 5s 0s ease both;
    fill: none;
    opacity: 0.8;
  }
  .ct-series-b {
    stroke-dasharray: 1665;
    stroke-dashoffset: 1665;
    animation: draw 5s 0.25s ease both;
    fill: none;
    opacity: 0.8;
  }
  .ct-series-c {
    stroke-dasharray: 1644;
    stroke-dashoffset: 1644;
    animation: draw 5s 0.5s ease both;
    fill: none;
    opacity: 0.8;
  }
  .ct-series-d {
    stroke-dasharray: 1540;
    stroke-dashoffset: 1540;
    animation: draw 5s 0.75s ease both;
    fill: none;
    opacity: 0.8;
  }
  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
</style>
